@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary-color: #041C32;
    --secondary-color: #04293A;
    --third-color: #064663;
    --white-color: #efefef;
}

.preload-screen {
    background-color: var(--primary-color);
    height: 100vh;
    width: 100%;
}

img.logo-preload {
    width: 200px;
    height: 180px;
}

.title-preload {
    font-family: 'Poppins', sans-serif;
    color: var(--white-color);
    margin-top: 8px;
}