@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary-color: #041C32;
    --secondary-color: #04293A;
    --third-color: #064663;
    --white-color: #efefef;
}

.btn-read-tafsir {
    font-family: 'Poppins', sans-serif;
    background-color: var(--third-color) !important;
    color: var(--white-color) !important;
    margin-left: 5px;
}

.tafsir-text {
    margin-top: 12px;
    margin-bottom: 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
}