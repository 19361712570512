@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary-color: #041C32;
    --secondary-color: #04293A;
    --third-color: #064663;
    --white-color: #efefef;
}

.about-wrapper {
    font-family: 'Poppins', sans-serif;
    margin-top: 100px;
}

.about-title {
    font-weight: 600;
    color: var(--primary-color);
}

.about-link, .about-link:hover {
    color: #38a9c5;
}

.logo-react {
    color: #4fbfdb;
}