@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary-color: #041C32;
    --secondary-color: #04293A;
    --third-color: #064663;
    --white-color: #efefef;
}

footer {
    font-family: 'Poppins', sans-serif;
    background-color: var(--primary-color);
    padding: 20px 0;
    margin-top: 50px;
}

footer p {
    margin: 0;
}

.footer-brand, .footer-about {
    color: var(--white-color);
}

.footer-brand {
    padding-bottom: 4px;
}

.footer-about:hover {
    color: var(--white-color);
}

.footer-create {
    color: var(--white-color);
    font-weight: 300;
    font-size: 14px;
}