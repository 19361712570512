@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary-color: #041C32;
    --secondary-color: #04293A;
    --third-color: #064663;
    --white-color: #efefef;
}

.nav-surah {
    margin-top: 62px;
    background-color: rgb(238, 238, 238);
    padding: 4px 0px !important;
}

.nav-surah-title {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px !important;
    color: #000 !important;
    text-decoration: none;
}

.nav-total-ayat {
    margin: 0;
    font-weight: 300;
}

.nav-back a {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 16px;
    text-decoration: none;
    color: #000;
}
.nav-back a:hover {
    color: #000;
}

.ayat-container {
    padding-top: 120px;
}

.ayat-wrapper.col {
    padding: 30px 16px 25px 16px;
    border-bottom: 1px solid var(--primary-color);
}

.bismillah {
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 34px;
    margin-top: 40px;
}

.ayat {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 34px;
    text-align: end;
}

.ayat-number {
    font-weight: 800;
    background-color: var(--primary-color);
    padding: 4px 10px;
    color: var(--white-color);
    border-radius: 0.25rem;
}

.ayat-translation {
    font-family: 'Poppins', sans-serif;
    line-height: 32px;
}

.loading {
    font-family: 'Poppins', sans-serif;
    margin-top: 100px;
}