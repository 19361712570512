@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
    --primary-color: #041C32;
    --secondary-color: #04293A;
    --third-color: #064663;
    --white-color: #efefef;
}

.notfound-wrapper {
    font-family: 'Poppins', sans-serif;
    margin-top: 100px;
}

.notfound-title {
    font-weight: 600;
}

.btn-back {
    background-color: var(--primary-color) !important;
    border: 1px solid var(--primary-color) !important;
}

.btn-back a, .btn-back a:hover {
    color: var(--white-color);
    text-decoration: none;
}